import './App.css';
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from 'react';
import { useQuery } from '@apollo/client';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    ArcElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Doughnut } from "react-chartjs-2";
import { lngs } from "./Constants";
import {
    getBackgroundColor,
    getBorderColor,
    getType3Options,
    getOptions,
    getType3BackgroundColor,
    getRightWrongColor, getType3BorderColor, getWrongRightColor
} from "./Utils";
import { QUERY_DATA } from "./GqlQueries";

ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const initialDataSets = [];

function StatsView(props) {
    const { i18n } = useTranslation();
    // if we need to handle errors at some point:
    // const { loading, error, data } = useQuery(QUERY_DATA, {

    const [episodeNumber, setEpisodeNumber] = useState(1);

    let { loading, error, data, refetch } = useQuery(QUERY_DATA, {
        variables: {gameName: props["gameName"], episodeNumber: episodeNumber},
        fetchPolicy: "network-only",
    });
    const [dataSets, setDataSets] = useState(initialDataSets);
    const [lang, setLang] = useState(() => {
        if(['fi', 'en', 'sv'].includes(i18n.language))
        {
            return i18n.language;
        }
        if (i18n.language.startsWith('en'))
        {
            return 'en';
        }
        return 'fi';
    });


    const beforeafter_images = {
        fi: '/beforeafter_webp/31_fi.webp',
        sv: '/beforeafter_webp/31_sv.webp',
        en: '/beforeafter_webp/31_en.webp',
    }

    const episode_strs = {fi: 'Episodi', en: 'Episode', 'sv': 'Episod'};
    const refetch_strs = {fi: 'Päivitä tiedot', en: 'Update data', sv: 'Uppdatera data'};


    useEffect(() => {
        if(i18n.language)
        {
            setLang(i18n.language)
        }
    }, [i18n.language]);


    useEffect(() => {
        void refetch();
    }, [episodeNumber]);

    // parse and process the data. triggered by new data from API query or language change.
    useEffect(() => {
        const edges = data?.choices?.edges;
        // data is ordered by questionId and by choiceId.
        let newDataSets = []
        console.log("edges: ");
        console.log(edges);
        if(edges && edges.length > 0)
        {
            const suffix = lngs[lang].suffix || 'Fi'
            // add a new graph: label and an array of data sets.
            let currentQuestionId = edges[0].node['questionId'];
            let currentLabel = edges[0].node['questionTitle' + suffix];
            let currentType = edges[0].node['type'];
            let currentLabels = [];
            let currentImageUrl = edges[0].node['imageUrl'] + suffix.toLowerCase() + '.webp';
            let currentChoiceId = edges[0].node['choiceId'];
            let currentData = [];
            let currentDataSets = [];
            let currentBgColors = [];
            let currentBorderColors = [];
            let bgcolorindex = 0;
            let currentIndex = 0;
            let correctIndex = 0;
            for (let i = 0; i < edges.length; i++)
            {
                const node = edges[i].node;
                if(currentQuestionId !== node['questionId'] &&
                    !(
                        currentChoiceId.includes('MinigameTexting') &&
                        node['choiceId'].includes('MinigameTexting')
                    )
                )
                {
                    currentIndex = 0;
                    if([1, 2, 3, 4, 5].includes(currentType))
                    {
                        newDataSets.push({
                            graphTitle: currentLabel,
                            imageUrl: currentImageUrl,
                            labels: [''],
                            type: currentType,
                            datasets: currentDataSets
                        });
                    }
                    else
                    {
                        newDataSets.push({
                            graphTitle: currentLabel,
                            labels: currentLabels,
                            type: currentType,
                            correctIndex: correctIndex,
                            datasets: [
                                {
                                    label: currentLabel,
                                    data: currentData,
                                    backgroundColor: currentBgColors,
                                    borderColor: currentBorderColors,
                                    borderWidth: 1
                                }
                            ]
                        });
                    }
                    currentQuestionId = node['questionId'];
                    bgcolorindex = 0;
                    currentDataSets = []
                    currentQuestionId = node['questionId'];
                    currentLabel = node['questionTitle' + suffix];
                    currentImageUrl = node['imageUrl'] + suffix.toLowerCase() + '.webp';
                    currentType = node['type'];
                    currentData = [];
                    currentLabels = [];
                    currentBgColors = [];
                    currentBorderColors = [];
                }
                if([1, 2, 3, 4, 5].includes(currentType) )
                {
                    currentDataSets.push({
                        label: node['choiceText' + suffix].includes('emoj') ? '🍆👅😝🌮💦' : node["choiceText" + suffix],
                        data: [node["choiceCount"]],
                        backgroundColor: ([1, 2, 4, 5].includes(currentType)) ? ((currentImageUrl.length > 0) && (currentType === 1)) ? getWrongRightColor(bgcolorindex) : getBackgroundColor(bgcolorindex) : getType3BackgroundColor(bgcolorindex),
                        isCorrect: node["isCorrect"],
                    })
                }
                else
                {
                    if(node['isCorrect'] === true)
                    {
                        correctIndex = currentIndex;
                    }

                    //currentLabels.push(node['choiceText' + suffix]);
                    currentLabels.push(node['choiceText' + suffix]);
                    currentData.push(node["choiceCount"]);
                    currentBgColors.push(getType3BackgroundColor(bgcolorindex));
                    currentBorderColors.push(getType3BorderColor(bgcolorindex));
                }
                bgcolorindex++;
                currentIndex++;
            }

            if([1, 2, 3, 4, 5].includes(currentType))
            {
                newDataSets.push({
                    graphTitle: currentLabel,
                    imageUrl: currentImageUrl,
                    labels: [''],
                    type: currentType,
                    datasets: currentDataSets
                });
            }
            else
            {
                newDataSets.push({
                    graphTitle: currentLabel,
                    labels: currentLabels,
                    type: currentType,
                    correctIndex: correctIndex,
                    datasets: [
                        {
                            label: currentLabel,
                            data: currentData,
                            backgroundColor: currentBgColors,
                            borderColor: currentBorderColors,
                            borderWidth: 1
                        }
                    ]
                });
            }
            setDataSets(newDataSets);
        }
        else
        {
            setDataSets(initialDataSets);
        }
    }, [data, lang]);

    const generateSomenatorPictureHtml = (dataSet) => {
        const suffix = lngs[lang].suffix || 'Fi';
        return(
            <>
                <div className="w-full grid m-auto place-items-center justify-center pt-7">
                    <p className="pb-2 text-sm text-gray-500">{dataSet["graphTitle"]}</p>
                    <img src={'./somenator_webp/' + dataSet["questionId"] + suffix + '.webp'} alt={"kikture"}/>
                </div>
                <div className="w-7/12 m-auto justify-center pt-5">
                    <Bar className={'pb-1'} options={getType3Options(dataSet["graphTitle"])} data={dataSet} width={100} height={60} />
                </div>
                <dl>
                    {dataSet.datasets.map((data, index) => (
                        <div key={index.toString()} className="pl-8 flex items-start pb-1">
                            <div className="text-center rounded-md w-10"
                                 style={{backgroundColor: getType3BackgroundColor(index)}}>
                                <dt className="text-sm text-gray-500">{data.data[0].toString()}</dt>
                            </div>
                            <dd className={data.isCorrect ? "text-sm text-black pl-2" : "text-sm text-gray-500 pl-2"}>{data.label}</dd>
                        </div>
                    ))}
                </dl>
            </>
        )
    }

    return (
        <div className="grid grid-cols-1 place-content-center place-items-center">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-1 w-42 mb-3" onClick={() => refetch()}>{refetch_strs[lang.toLowerCase()] + ' '}</button>
            <div>
                <button className={((episodeNumber) === 1 ? "bg-blue-500 " : "bg-blue-300 ") + "hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-42 mb-3"} onClick={() => setEpisodeNumber(1)}>{episode_strs[lang.toLowerCase()]} 1</button>
                <button className={((episodeNumber) === 2 ? "bg-blue-500 " : "bg-blue-300 ") + "hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-42 mb-3 mr-2 ml-2"} onClick={() => setEpisodeNumber(2)}>{episode_strs[lang.toLowerCase()]} 2</button>
                <button className={((episodeNumber) === 3 ? "bg-blue-500 " : "bg-blue-300 ") + "bg-blue-300 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-42 mb-3"} onClick={() => setEpisodeNumber(3)}>{episode_strs[lang.toLowerCase()] + ' '} 3</button>
            </div>
            {
                dataSets && (dataSets.length > 0) && dataSets.map((dataSet, index) => (
                    <div key={index.toString()} className="w-5/6 max-w-3xl">
                        {dataSet.type === 1 && dataSet.questionId === "01"
                            && <>

                            </>
                         }
                        {dataSet.type === 10
                            && <>
                                <p className="text-sm text-gray-500 text-center">{dataSet["graphTitle"]}</p>
                                <Bar options={getOptions(dataSet["graphTitle"])} data={dataSet} width={200}
                                     height={100}/>
                                <dl>
                                    {dataSet.datasets.map((data, index) => (
                                        <div key={index.toString()} className="pl-8 flex items-start pb-1">
                                            <div className="text-center rounded-md w-10"
                                                 style={{backgroundColor: getBackgroundColor(index)}}>
                                                <dt className="text-sm text-gray-500">{data.data[0].toString()}</dt>
                                            </div>

                                            <dd className={data.isCorrect ? "text-sm text-black pl-2" : "text-sm text-gray-500 pl-2"}>{data.label}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </>
                        }
                        {dataSet.type === 1
                            && <>
                                {console.log(dataSet)}
                                {dataSet["imageUrl"] && <>
                                    <div className="mt-5 w-full mb-4 grid m-auto place-items-center justify-center pt-7">
                                        <p className="pb-2 text-base text-gray-500">{dataSet["graphTitle"]}</p>
                                        <img src={'/somenator_webp/' + dataSet["imageUrl"]} alt={"kikture"}/>
                                    </div>
                                </>
                                }
                                <Bar options={getOptions(dataSet["graphTitle"])} data={dataSet} width={200}
                                     height={100}/>
                                <dl>
                                    {dataSet.datasets.map((data, index) => (
                                        <div key={index.toString()} className="pl-8 flex items-start pb-1">
                                            <div className="text-center rounded-md w-10"
                                                 style={{backgroundColor: getWrongRightColor(index)}}>
                                                <dt className="text-sm text-gray-500">{data.data[0].toString()}</dt>
                                            </div>
                                            <div className="align-middle text-center rounded-md ml-1 mr-1 w-6 flex-shrink-0">
                                                <dt className="text-sm text-gray-500">{data.isCorrect ? ' ✅ ' : ' ❌ '}</dt>
                                            </div>
                                            <dd className="text-sm text-gray-700">{data.label}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </>
                        }
                        {dataSet.type === 2 &&
                            <>
                                <div className="w-5/12 m-auto justify-center pt-7">
                                    <p className="pb-2 text-sm text-gray-500 text-center">{dataSet["graphTitle"]}</p>
                                </div>
                                <Bar options={getOptions(dataSet["graphTitle"])} data={dataSet} width={200}
                                     height={100}/>
                                <dl>
                                    {dataSet.datasets.map((data, index) => (
                                        <div key={index.toString()} className="pl-8 flex items-start pb-1">
                                            <div className="text-center rounded-md w-10"
                                                 style={{backgroundColor: getBackgroundColor(index)}}>
                                                <dt className="text-sm text-gray-500">{data.data[0].toString()}</dt>
                                            </div>
                                            <div className="align-middle text-center rounded-md ml-1 mr-1 w-6 flex-shrink-0">
                                                <dt className="text-sm text-gray-500">{data.isCorrect ? ' ✅ ' : ' ❌ '}</dt>
                                            </div>
                                            <dd className="text-sm text-gray-700">{data.label}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </>
                        }
                        {false && dataSet.type === 2 &&
                            <>
                                <div className="w-5/12 m-auto justify-center pt-7">
                                    <p className="pb-2 text-sm text-gray-500 text-center">{dataSet["graphTitle"]}</p>
                                    <Doughnut className={'pb-6'} options={getOptions(dataSet["graphTitle"])} data={dataSet} width={100} height={100} />


                                </div>
                                <dl>
                                    {dataSet.labels.map((data, index) => (
                                        <div key={index.toString()} className={"pl-8 flex items-start pb-1"}>
                                            <div className="text-center rounded-md w-10" style={{backgroundColor:getBackgroundColor(index)}}>
                                                <dt className="text-sm text-gray-500">{dataSet.datasets[0].data[index]}</dt>
                                            </div>
                                            <dd className={dataSet.correctIndex === index ? "text-sm text-black pl-2" : "text-sm text-gray-500 pl-2"}>{data}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </>
                        }
                        {dataSet.type === 3 && <>
                        <div className="w-full grid m-auto place-items-center justify-center pt-7">
                            <p className="pb-2 text-sm text-gray-500">{dataSet["graphTitle"]}</p>
                                <img src={'./testpicture.jpg'} alt={"kikture"}/>
                        </div>
                        <div className="w-7/12 m-auto justify-center pt-5">
                            <Bar className={'pb-1'} options={getType3Options(dataSet["graphTitle"])} data={dataSet} width={100} height={60} />
                        </div>
                            <dl>
                                {dataSet.datasets.map((data, index) => (
                                    <div key={index.toString()} className="pl-8 flex items-start pb-1">
                                        <div className="text-center rounded-md w-10"
                                             style={{backgroundColor: getType3BackgroundColor(index)}}>
                                            <dt className="text-sm text-gray-500">{data.data[0].toString()}</dt>
                                        </div>
                                        <dd className={data.isCorrect ? "text-sm text-black pl-2" : "text-sm text-gray-500 pl-2"}>{data.label}</dd>
                                    </div>
                                ))}
                            </dl>
                        </>
                        }
                        {dataSet.type === 4
                            && <>
                                {console.log(dataSet)}
                                <p className="text-base text-gray-500 text-center">{dataSet["graphTitle"]}</p>
                                <Bar options={getOptions(dataSet["graphTitle"])} data={dataSet} width={200}
                                     height={100}/>
                                <dl>
                                    {dataSet.datasets.map((data, index) => (
                                        <div key={index.toString()} className="pl-8 flex items-start pb-1">
                                            <div className="text-center rounded-md w-10  flex-shrink-0"
                                                 style={{backgroundColor: getBackgroundColor(index)}}>
                                                <dt className="text-sm text-gray-500">{data.data[0].toString()}</dt>
                                            </div>
                                            <div className="align-middle text-center rounded-md ml-1 mr-1 w-6 flex-shrink-0">
                                                <dt className="text-sm text-gray-500">{data.isCorrect ? ' ✅ ' : ' ❌ '}</dt>
                                            </div>
                                            <dd className={"max-w-xl text-sm text-gray-700"}> {data.label}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </>
                        }
                        {dataSet.type === 5
                            && <>
                                {console.log(dataSet)}
                                <p className="mt-10 mb-4 text-base text-gray-500 text-center">{dataSet["graphTitle"]}</p>
                                <img className="mb-4" src={beforeafter_images[lang.toLowerCase()]} alt={"kikture"}/>
                                <Bar options={getOptions(dataSet["graphTitle"])} data={dataSet} width={200}
                                     height={100}/>
                                <dl>
                                    {dataSet.datasets.map((data, index) => (
                                        <div key={index.toString()} className="pl-8 flex items-start pb-1">
                                            <div className="text-center rounded-md w-10 flex-shrink-0"
                                                 style={{backgroundColor: getBackgroundColor(index)}}>
                                                <dt className="text-sm text-gray-500">{data.data[0].toString()}</dt>
                                            </div>
                                            <div className="align-middle text-center rounded-md ml-1 mr-1 w-6 flex-shrink-0">
                                                <dt className="text-sm text-gray-500">{data.isCorrect ? ' ✅ ' : ' ❌ '}</dt>
                                            </div>

                                            <dd className={"max-w-xl text-sm text-gray-700"}> {data.label}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </>
                        }
                        {dataSet.type === 6 && <>
                            <div className="w-full grid m-auto place-items-center justify-center pt-7">
                                <p className="pb-2 text-sm text-gray-500">{dataSet["graphTitle"]}</p>
                                <img src={'/teasing_webp/0' + index.toString() + '_' + lang.toLowerCase() + '.webp'} alt={"kikture"}/>
                            </div>
                            <div className="w-7/12 m-auto justify-center pt-5">
                                <Bar className={'pb-1'} options={getType3Options(dataSet["graphTitle"])} data={dataSet} width={100} height={60} />
                            </div>
                            <dl>
                                {dataSet.labels.map((data, index) => (
                                    <div key={index.toString()} className={"pl-8 flex items-start pb-1"}>
                                        <div className="text-center rounded-md w-10" style={{backgroundColor:getType3BackgroundColor(index)}}>
                                            <dt className="text-sm text-gray-500">{dataSet.datasets[0].data[index]}</dt>
                                        </div>
                                        <dd className={"max-w-xl text-sm text-gray-700 ml-2"}>{data}</dd>
                                    </div>
                                ))}
                            </dl>
                        </>
                        }
                        {dataSet.type > 6 && <>
                            <p className="pb-2 text-xs text-gray-500">{dataSet["graphTitle"]}</p>
                            <Bar className={'pb-6'} options={getOptions(dataSet["graphTitle"])} data={dataSet} width={100} height={100} />
                            <dl>
                                {dataSet.labels.map((data, index) => (
                                    <div key={index.toString()} className={"pl-8 flex items-start pb-1"}>
                                        <div className="text-center rounded-md" style={{backgroundColor:getBackgroundColor(index)}}>
                                            <dt className="text-xs text-gray-500">{dataSet.datasets[0].data[index]}</dt>
                                        </div>
                                        <dd className="text-xs text-gray-500 pl-2">{data}</dd>
                                    </div>
                                ))}
                            </dl>
                        </>
                        }
                    </div>
                ))
            }
        </div>
    );
}

export default StatsView;
