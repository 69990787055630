export const getBackgroundColor = (index) => {
    const backgroundColors = [
        'rgba(255, 99, 132, 0.33)',
        'rgba(54, 162, 235, 0.33)',
        'rgba(255, 206, 86, 0.33)',
        'rgba(75, 192, 192, 0.33)',
        'rgba(153, 102, 255, 0.33)',
        'rgba(255, 159, 64, 0.33)',
    ]
    return (backgroundColors[index % backgroundColors.length]);
};

export const getRightWrongColor = (index) => {
    const rightWrongColors = [
        'rgba(88, 233, 88, 0.88)',
        'rgba(233, 88, 88, 0.88)',
    ]
    return (rightWrongColors[index % rightWrongColors.length]);
};

export const getBorderColor = (index) => {
    const borderColors = [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
    ]
    return (borderColors[index % borderColors.length]);
};

export const getWrongRightColor = (index) => {
    const wrongRightcolors = [
        'rgba(203, 72, 5, 0.5)',
        'rgba(20, 144, 97, 0.5)',
    ]
    return(wrongRightcolors[index % wrongRightcolors.length]);
}


export const getType3BackgroundColor = (index) => {
    const backgroundColors = [
        '#ecdc33',
        '#dc8f00',
        '#c75100',
    ]
    return (backgroundColors[index % backgroundColors.length]);
};

export const getType3BorderColor = (index) => {
    const backgroundColors = [
        '#9a8e12',
        '#936c22',
        '#592400',
    ]
    return (backgroundColors[index % backgroundColors.length]);
};

export const fabricateData = (name, dataLength) => {
    let fabLabels = [name];
    let fabDatasets = [];
    for (let i = 0; i < dataLength; i++)
    {
        fabDatasets.push({
            label: "Option " + (i+1).toString(),
            data: [Math.floor(Math.random() * 30)],
            backgroundColor: getBackgroundColor(i)
        })
    }
    return {
        labels: fabLabels,
        datasets: fabDatasets
    };
};

export const getType3Options = (title, horizontal=false) => {
    return {
        indexAxis: horizontal ? 'y' : 'x',
        responsive: true,
        plugins: {
            legend: {
                labels:
                    {
                        pointStyle: 'rectRounded',
                        usePointStyle: true
                    },
                fullSize: false,
                display: false,
                position: 'top',
                align: 'start',
            },
            title: {
                display: false,
                text: title,
            },
        },
    };
};

export const getOptions = (title) => {
    return {
        responsive: true,
        plugins: {
            legend: {
                labels:
                    {
                        pointStyle: 'rectRounded',
                        usePointStyle: true
                    },
                fullSize: false,
                display: false,
                position: 'top',
                align: 'start',
            },
            title: {
                display: false,
                text: title,
            },
        },
    };
};


export const getStackedOptions = (title) => {
    return {
        responsive: true,
        indexAxis: 'y',
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
        plugins: {
            legend: {
                labels:
                    {
                        pointStyle: 'rectRounded',
                        usePointStyle: true
                    },
                fullSize: false,
                display: false,
                position: 'top',
                align: 'start',
            },
            title: {
                display: true,
                text: title,
            },
        },
    };
};


export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: 'bottom',
        },
        title: {
            display: true,
            text: 'Misc. chartti',
        },
    },
};