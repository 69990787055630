import './App.css';
import {useTranslation} from "react-i18next";
import React, { useState, useEffect } from 'react';
import Flag from 'react-world-flags';
import StatsView from "./StatsView";
import { lngs } from "./Constants";
import Footer from "./Footer";


function App() {
    const { t, i18n } = useTranslation();
    const [gameName, setGameName] = useState('');
    // 0 = default
    // 1 = teacher trying to log in
    const [pageState, setPageState] = useState(2);

    const [lang, setLang] = useState(() => {
        if(['fi', 'en', 'sv'].includes(i18n.language))
        {
            return i18n.language;
        }
        if (i18n.language.startsWith('en'))
        {
            return 'en';
        }
        return 'fi';
    });

    useEffect(() => {
        if(i18n.language)
        {
            setLang(i18n.language)
        }
    }, [i18n.language]);

    const teacherLogin = async () => {
        console.log("setting page state to 1");
        setPageState(1);
    };

    const teacherFrontPage = async () => {
        console.log("setting page state to 1");
        setPageState(0);
    };

    const gameNameChanged = (event) => {
        console.log("game name changed.");
        setGameName(event.target.value);
    };



    return (
        <div className="bg-slate-100">
        {pageState === 0 &&
        <div className="flex h-screen">
            <div className="m-auto">
                <div className="flex-auto w-full items-center align-middle">
                    <form className="bg-white shadow-md rounded-md px-8 pt-6 pb-8">
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2.5" htmlFor="gamename">
                                {t('gameId_heading')}
                            </label>
                            <input
                                className="mb-0.5 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="username" type="text" placeholder={t('gameId_heading')} onChange={gameNameChanged}/>
                            <p className="text-green-600 text-xs italic">{t('gameId_help')}</p>
                        </div>
                        <div className="flex items-center justify-between">
                            <button
                                className="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                type="button" onClick={teacherLogin}>
                                {t('openGame_button')}
                            </button>
                        </div>
                    </form>
                    <div className="flex w-full place-content-center mb-2">
                        {Object.keys(lngs).map((lng) => (
                            lngs[lng].alpha3.map(
                                (a3, index) => (
                                    <div key={index.toString()} className="w-3 ml-1.5 mr-1.5 h-10 w-6" onClick={() => i18n.language !== lngs[lng].langcode && i18n.changeLanguage(lngs[lng].langcode)}>
                                        <Flag code={ a3 } className="h-10 w-10 flex-no-shrink fill-current" />
                                    </div>
                                )
                            )
                        ))}
                    </div>
                    <Footer/>
                </div>
            </div>

        </div>
        }
        { pageState === 1
              &&
            <>
                <div className="flex w-full place-content-center pt-7">{gameName}</div>
                <div className="flex w-full place-content-center">
                    {Object.keys(lngs).map((lng) => (
                        lngs[lng].alpha3.map(
                            (a3, index) => (
                                <div key={index.toString()} className="w-3 ml-1.5 mr-1.5 h-10 w-6" onClick={() => i18n.language !== lngs[lng].langcode && i18n.changeLanguage(lngs[lng].langcode)}>
                                    <Flag code={ a3 } className="h-10 w-10 flex-no-shrink fill-current" />
                                </div>
                            )
                        )
                    ))}
                </div>
                <StatsView gameName={gameName}/>
                <Footer/>
            </>
        }
        { pageState === 2
            &&
            <div className="flex h-screen">
                <div className="m-auto">
                    <div className="flex-auto w-full items-center align-middle">
                        <div className="ml-10 mr-10 flex-auto items-center max-w-lg mb-4">
                            <img onClick={() => {window.location = '/Build/index.html'}} className="rounded-xl opacity-60 hover:opacity-90" src={'hero_screenshots/hero_screenshot_' + lang + '.jpg'}/>
                        </div>
                        <form className="ml-6 mr-6 bg-white shadow-md rounded-md px-8 pt-6 pb-8">
                            <div className="flex items-center justify-between">
                                <button
                                    className="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-6 mt-2"
                                    type="button" onClick={() => {window.location = '/Build/index.html'}}>
                                    {t('playGame_button')}
                                </button>
                            </div>
                            <div className="flex items-center justify-between">
                                <button
                                    className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    type="button" onClick={teacherFrontPage}>
                                    {t('viewStats_button')}
                                </button>
                            </div>
                        </form>
                        <div className="flex w-full place-content-center mb-2">
                            {Object.keys(lngs).map((lng) => (
                                lngs[lng].alpha3.map(
                                    (a3, index) => (
                                        <div key={index.toString()} className="w-3 ml-1.5 mr-1.5 h-10 w-6" onClick={() => i18n.language !== lngs[lng].langcode && i18n.changeLanguage(lngs[lng].langcode)}>
                                            <Flag code={ a3 } className="h-10 w-10 flex-no-shrink fill-current" />
                                        </div>
                                    )
                                )
                            ))}
                        </div>
                        <Footer/>
                    </div>
                </div>
            </div>

        }

      </div>
    );
}

export default App;
