import {gql} from "@apollo/client";

export const QUERY_DATA = gql`
	query getChoices($gameName: String!, $episodeNumber: String!) {
		choices(gameId_Iexact: $gameName, episodeNumber: $episodeNumber) {
			edges
			{
			    node
			    {
			        id
			        gameId
			        questionId
			        isCorrect
			        type
			        questionTitleFi
                    questionTitleSv
                    questionTitleEn
                    choiceId
                    choiceTextFi
                    choiceTextSv
                    choiceTextEn
                    choiceCount
                    imageUrl
			    }
			}
		}
	}
`;