import './App.css';
import React, {useState} from 'react';

const logoImageUrls = ['/headnought_logo_2019_web_black_s.png', '/fad_games_logo_web.png']
const logoLinks = ['https://www.headnought.com', 'https://www.fadgames.com']

function Footer() {

    // randomize the order or the creator logos for full equality.
    // brothers of metal.
    const [order, setOrder] = useState(Math.random() >= 0.5 ? [0, 1] : [1, 0]);

    return (
        <>
            <div className="flex w-full place-content-center pt-3">
                <p className="text-center text-gray-500 text-xs">
                    2023 SoMe Detectives
                </p>
            </div>
            <div className="flex w-full place-content-center pt-3">
                <a href={logoLinks[order[0]]}><img className="object-fill h-8 pr-4" src={logoImageUrls[order[0]]} alt={"Headnought"}/></a>
                <a href={logoLinks[order[1]]}><img className="object-fill h-8 pl-4" src={logoImageUrls[order[1]]} alt={"Fad games"} /></a>
            </div>
        </>
    );
}

export default Footer;
